import axios from "axios";

export default axios.create({
  // baseURL: "http://localhost:8080",
  baseURL: process.env.VUE_APP_BACKEND,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
    // 'Access-Control-Allow-Origin': "*"
  }
});
